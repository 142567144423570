/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/**
 * Styles targeting the Event sections
 *
 */
/* =Event List
----------------------------------------------------------*/
.event-list__filter {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  row-gap: 6px;
  column-gap: 14px;
}

@media screen and (max-width: 767px) {
  .event-list__filter {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
}

.event-list__filter .form-select {
  width: auto;
  height: 44px;
  max-width: none;
  min-width: 260px;
}

.event-list__filter label {
  font-size: 15px;
  color: #555555;
}

.event-list__filter label, .event-list__filter .form-item {
  margin: 0;
}

@media screen and (min-width: 992px) {
  .event-list__index {
    border: 3px solid #dddddd;
    min-height: 400px;
  }
}

.event-list__btns {
  background: #f7f7f7;
  margin: 0;
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

@media screen and (max-width: 767px) {
  .event-list__btns {
    padding: 10px 20px;
  }
}

.event-list__btn {
  font-size: 14px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  color: #666666;
  padding: 5px 20px 5px 40px;
  background-color: #f7f7f7;
  background-size: 18px 18px;
  background-position: 12px 7px;
  background-repeat: no-repeat;
}

.event-list__btn.active {
  color: #cf4520;
  background-color: #fff;
}

.event-list__btn[data-target="upcoming-events"] {
  background-image: url("../../../../themes/wcm_brand_base/images/cta_icons/SVG/list.svg");
}

.event-list__btn[data-target="past-events"] {
  background-image: url("../../../../themes/wcm_brand_base/images/cta_icons/SVG/appointment.svg");
}

.event-list__btn[data-target="calendar-events"] {
  background-image: url("../../../../themes/wcm_brand_base/images/cta_icons/SVG/calendar.svg");
}

@media screen and (max-width: 767px) {
  .event-list__btn[data-target="calendar-events"] {
    display: none;
  }
}

.event-list__view .view,
#calendar {
  margin-top: 20px;
}

.event-list__no-results {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .event-list__no-results {
    margin-bottom: 80px;
  }
}

/* =Event Teaser
----------------------------------------------------------*/
.view-events .wcm-event {
  margin: 0 10px 30px 0;
  padding: 0 0 30px;
  border-bottom: 1px solid #dddddd;
}

.view-events .wcm-event:before, .view-events .wcm-event:after {
  content: " ";
  display: table;
}

.view-events .wcm-event:after {
  clear: both;
}

.views-row-last .wcm-event {
  border-bottom: none;
  padding: 0;
}

.event__date {
  color: #666666;
}

@media screen and (min-width: 768px) {
  .event__date {
    text-align: center;
    padding-left: 10px;
  }
}

.event__date--month {
  font-size: 20px;
  line-height: 1.0;
}

.event__date--day {
  font-size: 32px;
}

.event__date--full,
.event__time,
.event__location {
  font-size: 13px;
}

.event__date--full {
  margin-right: 10px;
  padding-right: 14px;
  border-right: 1px solid #dddddd;
}

.event__location {
  margin-left: 10px;
  padding-left: 14px;
  border-left: 1px solid #dddddd;
}

.event__location div {
  display: inline;
}

img.event-image {
  width: 160px;
  height: auto;
  margin: 10px 0;
}

@media screen and (min-width: 768px) {
  img.event-image {
    float: right;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  img.event-image {
    width: 135px;
  }
}

@media screen and (min-width: 1200px) {
  img.event-image {
    width: 160px;
  }
}

@media screen and (min-width: 992px) {
  .event img.event-image {
    margin-right: 20px;
  }
}

.panel-twocol .event__title {
  margin-bottom: 5px;
}

.panel-twocol .field-label {
  font-weight: 600;
  display: inline;
}

/* =Calendar
----------------------------------------------------------*/
#calendar {
  padding: 0;
}

#calendar .fc-header-toolbar .fc-button {
  border: none;
  background: none;
  box-shadow: none;
  color: #e7751d;
  float: left;
  padding: 0;
}

#calendar .fc-header-toolbar .fc-toolbar-title {
  float: left;
}

#calendar .fc-col-header .fc-col-header-cell a {
  text-decoration: none;
  border: none;
  color: #000000;
}

#calendar .fc-daygrid-day .fc-daygrid-day-top a {
  text-decoration: none;
  border: none;
  color: #000000;
}

#calendar .fc-daygrid-day .fc-daygrid-day-events .fc-daygrid-event-harness .fc-daygrid-event {
  white-space: normal;
  color: #cf4520;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
}

#calendar tbody {
  border: none;
}

/* =Calendar Event Modal
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.fancybox-inner {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.fancybox-inner #wcm-event {
  margin: 0;
  padding: 20px 0;
  border-bottom: none;
  overflow: hidden;
}

.fancybox-inner #wcm-event .event__title {
  font-size: 20px;
  font-weight: 700;
  color: #cf4520;
}

.fancybox-inner #wcm-event .event__date--month {
  padding-top: 5px;
}

.fancybox-inner #wcm-event .event__date--day {
  line-height: 1.2;
}

.fancybox-inner #wcm-event .event__info {
  padding-bottom: 5px;
}

.fancybox-inner #wcm-event .event__info .event__time {
  font-size: 15px;
  line-height: 25px;
}

.fancybox-inner #wcm-event .field-event-description {
  margin-right: 10px;
}

/* =Upcoming Events List (Vertical)
----------------------------------------------------------*/
.wcm-upcoming-events .wcm-event-calendar-link {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .wcm-upcoming-events .wcm-event-calendar-link {
    float: right;
  }
}

.wcm-upcoming-events h2 {
  font-size: 26px;
  margin: 20px 0;
}

.wcm-upcoming-events h2 a {
  color: #666666;
}

.wcm-upcoming-events h2 a:hover {
  color: #cf4520;
}

.wcm-upcoming-events > .container {
  margin-right: -20px;
}

.wcm-upcoming-events .wcm-upcoming-event {
  background: #fff;
  box-shadow: 1px 1px 2px 1px #dddddd;
  border: 1px solid #dddddd;
  padding: 20px;
  margin: 0 0 20px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events .wcm-upcoming-event {
    display: flex;
    margin-left: 30px;
    padding: 30px 30px 30px 0;
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .wcm-upcoming-events .wcm-upcoming-event {
    margin-left: 0;
  }
}

.wcm-upcoming-events .wcm-upcoming-event .event__info {
  margin: 0 0 5px 0;
  color: #666666;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events .wcm-upcoming-event .event__info {
    margin: 0 0 5px 15px;
  }
}

@media screen and (min-width: 1200px) {
  .wcm-upcoming-events .wcm-upcoming-event .event__info {
    margin: 0 0 5px 15px;
  }
}

.wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container {
  margin: 0 0 0 20px;
}

.wcm-upcoming-events .wcm-upcoming-event .event__title {
  font-weight: 700;
  color: #cf4520;
  margin: 0 0 5px 0;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events .wcm-upcoming-event .event__title {
    margin: 0 0 5px 15px;
  }
}

@media screen and (min-width: 992px) {
  .wcm-upcoming-events .wcm-upcoming-event .event__title {
    font-size: 12px;
  }
}

@media screen and (min-width: 1200px) {
  .wcm-upcoming-events .wcm-upcoming-event .event__title {
    font-size: 14px;
  }
}

.wcm-upcoming-events .wcm-upcoming-event .event__title a.external-link:after {
  display: none;
}

.wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__date span {
  display: block;
  text-align: center;
  line-height: 1.3;
  color: #666666;
}

.wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__date___month {
  font-size: 20px;
}

.wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__date___day {
  font-size: 34px;
}

.wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__icon {
  display: none;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__icon {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-width: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    left: -40px;
    margin-right: -20px;
    background: #fff;
  }
  .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__icon:hover {
    border-width: 0;
  }
  .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event__icon svg {
    position: absolute;
    width: 40px;
    height: 40px;
    fill: #e7751d;
    color: #cf4520;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 768px) {
  .col-md-3 .wcm-upcoming-events .wcm-upcoming-event,
  .col-md-2 .wcm-upcoming-events .wcm-upcoming-event,
  .col-md-1 .wcm-upcoming-events .wcm-upcoming-event {
    display: block;
    text-align: center;
    padding: 20px;
  }
  .col-md-3 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-icon-container,
  .col-md-2 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-icon-container,
  .col-md-1 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-icon-container {
    display: none;
  }
  .col-md-3 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container,
  .col-md-2 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container,
  .col-md-1 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container {
    margin: 0;
  }
  .col-md-3 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container .event__title,
  .col-md-2 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container .event__title,
  .col-md-1 .wcm-upcoming-events .wcm-upcoming-event .wcm-upcoming-event-info-container .event__title {
    margin: 0;
  }
}

/* =Upcoming Events List (Horizontal)
----------------------------------------------------------*/
.wcm-upcoming-events-section {
  margin: 20px 0 20px;
}

.wcm-upcoming-events-section:before, .wcm-upcoming-events-section:after {
  content: " ";
  display: table;
}

.wcm-upcoming-events-section:after {
  clear: both;
}

.wcm-upcoming-events-inner-section {
  position: relative;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events-inner-section {
    padding: 30px 0 0;
  }
}

.wcm-upcoming-events__view-all {
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .wcm-upcoming-events__view-all {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .wcm-upcoming-events__view-all {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .wcm-upcoming-event-horizontal {
    margin: 0 auto 20px;
    max-width: 300px;
  }
}

.wcm-upcoming-event-horizontal__image {
  position: relative;
  overflow: hidden;
  background-color: #000000;
}

.wcm-upcoming-event-horizontal__image a {
  display: block;
  border: none;
}

.wcm-upcoming-event-horizontal__image a:hover {
  border: none;
}

.wcm-upcoming-event-horizontal__image a:focus {
  border: 5px solid #000000;
}

.wcm-upcoming-event-horizontal__image:hover .wcm-upcoming-event-horizontal__date {
  display: none;
}

.wcm-upcoming-event-horizontal__image:hover a:before {
  opacity: 0;
}

.wcm-upcoming-event-horizontal__image:hover img {
  transform: scale(1.1);
}

.wcm-upcoming-event-horizontal__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.wcm-upcoming-event-horizontal__date {
  color: #666666;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: "1898Sans-Bold", sans-serif;
  letter-spacing: 2px;
  margin-top: 20px;
}

.wcm-upcoming-event-horizontal__time {
  font-size: 13px;
  margin: 10px 0 5px;
}

@media screen and (min-width: 992px) {
  .wcm-upcoming-event-horizontal__time {
    font-size: 15px;
    margin: 20px 0 10px;
  }
}

.wcm-upcoming-event-horizontal__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (min-width: 992px) {
  .wcm-upcoming-event-horizontal__title {
    font-size: 16px;
  }
}

/* =Event Node
----------------------------------------------------------*/
.wcm-event .field-event-link a {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
