/**
 * Styles targeting the Event sections
 *
 */

/* =Event List
----------------------------------------------------------*/

.event-list__filter {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  row-gap: 6px;
  column-gap: 14px;
  @include breakpoint($xs) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
  .form-select {
    width: auto;
    height: 44px;
    max-width: none;
    min-width: 260px;
  }
  label {
    font-size: 15px;
    color: $wcm-dark-gray;
  }
  label, .form-item {
    margin: 0;
  }
}

.event-list__index {
  @include breakpoint($md) {
    border: 3px solid $wcm-border-gray;
    min-height: 400px;
  }
}

.event-list__btns {
  background: $wcm-bg-gray;
  margin: 0;
  padding: 20px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  @include breakpoint($xs) {
    padding: 10px 20px;
  }
}
.event-list__btn {
  font-size: 14px;
  border: 1px solid $wcm-border-gray;
  border-radius: 20px;
  color: $wcm-med-gray;
  padding: 5px 20px 5px 40px;
  background-color: $wcm-bg-gray;
  background-size: 18px 18px;
  background-position: 12px 7px;
  background-repeat: no-repeat;
  &.active {
    color: $wcm-dark-orange;
    background-color: $wcm-white;
  }
  &[data-target="upcoming-events"] {
    background-image: url('../../../../themes/wcm_brand_base/images/cta_icons/SVG/list.svg');
  }
  &[data-target="past-events"] {
    background-image: url('../../../../themes/wcm_brand_base/images/cta_icons/SVG/appointment.svg');
  }
  &[data-target="calendar-events"] {
    background-image: url('../../../../themes/wcm_brand_base/images/cta_icons/SVG/calendar.svg');
    @include breakpoint($xs) {
      display: none;
    }
  }
}

.event-list__view .view,
#calendar {
  margin-top: 20px;
}

.event-list__no-results {
  text-align: center;
  @include breakpoint($xs) {
    margin-bottom: 80px;
  }
}

/* =Event Teaser
----------------------------------------------------------*/

.view-events .wcm-event {
  margin: 0 10px 30px 0;
  padding: 0 0 30px;
  border-bottom: 1px solid $wcm-border-gray;
  @include clearfix();
}

.views-row-last .wcm-event {
  border-bottom: none;
  padding: 0;
}

.event__date {
  color: $wcm-med-gray;
  @include breakpoint($sm) {
    text-align: center;
    padding-left: 10px;
  }
}

.event__date--month {
  font-size: 20px;
  line-height: 1.0;
}

.event__date--day {
  font-size: 32px;
}

.event__date--full,
.event__time,
.event__location {
  font-size: 13px;
}

.event__date--full {
  margin-right: 10px;
  padding-right: 14px;
  border-right: 1px solid $wcm-border-gray;
}

.event__location {
  margin-left: 10px;
  padding-left: 14px;
  border-left: 1px solid $wcm-border-gray;
  div {
    display: inline;
  }
}

img.event-image {
  width: 160px;
  height: auto;
  margin: 10px 0;
  @include breakpoint($sm) {
    float: right;
    margin: 0;
  }
  @include breakpoint($md) {
    width: 135px;
  }
  @include breakpoint($lg) {
    width: 160px;
  }
}

.event img.event-image {
  @include breakpoint($md) {
    margin-right: 20px;
  }
}

.panel-twocol {
  .event__title {
    margin-bottom: 5px;
  }
  .field-label {
    font-weight: 600;
    display: inline;
  }
}
/* =Calendar
----------------------------------------------------------*/

#calendar {
  padding: 0;
  .fc-header-toolbar {
    .fc-button {
      border: none;
      background: none;
      box-shadow: none;
      color: $wcm-bright-orange;
      float: left;
      padding: 0;
    }
    .fc-toolbar-title {
      float: left;
    }
  }
  .fc-col-header {
    .fc-col-header-cell {
      a {
        text-decoration: none;
        border: none;
        color: $wcm-black;
      }
    }
  }
  .fc-daygrid-day {
    .fc-daygrid-day-top {
      a {
        text-decoration: none;
        border: none;
        color: $wcm-black;
      }
    }
    .fc-daygrid-day-events {
      .fc-daygrid-event-harness {
        .fc-daygrid-event {
          white-space: normal;
          color: $wcm-dark-orange;
          font-size: 12px;
          font-weight: 700;
          line-height: 1.2;
        }
      }
    }
  }
  tbody {
    border: none;
  }
}

/* =Calendar Event Modal
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.fancybox-inner {
  overflow-x: hidden !important;
  overflow-y: auto !important;

  #wcm-event {
    margin: 0;
    padding: 20px 0;
    border-bottom: none;
    overflow: hidden;

    .event__title {
      font-size: $font-size-h3;
      font-weight: 700;
      color: $wcm-dark-orange;
    }

    .event__date--month {
      padding-top: 5px;
    }
    .event__date--day {
      line-height: 1.2;
    }
    .event__info {
      padding-bottom: 5px;

      .event__time {
        font-size: 15px;
        line-height: 25px;
      }
    }
    .field-event-description {
      margin-right: 10px;
    }
  }
}

/* =Upcoming Events List (Vertical)
----------------------------------------------------------*/

.wcm-upcoming-events {
  .wcm-event-calendar-link {
    text-align: center;
    @include breakpoint($lg) {
      float: right;
    }
  }
  h2 {
    font-size: 26px;
    margin: 20px 0;
    a {
      color: $wcm-med-gray;
      &:hover {
        color: $wcm-dark-orange;
      }
    }
  }
  > .container {
    margin-right: -20px;
  }

  .wcm-upcoming-event {
    background: $wcm-white;
    box-shadow: 1px 1px 2px 1px $wcm-border-gray;
    border: 1px solid $wcm-border-gray;
    padding: 20px;
    margin: 0 0 20px;
    text-align: center;
    @include breakpoint($sm) {
      display: flex;
      margin-left: 30px;
      padding: 30px 30px 30px 0;
      text-align: left;
    }
    @include breakpoint($md) {
      margin-left: 0;
    }
    .event__info {
      margin: 0 0 5px 0;
      color: $wcm-med-gray;
      @include breakpoint($sm) {
        margin: 0 0 5px 15px;
      }
      @include breakpoint($lg) {
        margin: 0 0 5px 15px;
      }
    }
    .wcm-upcoming-event-info-container {
      margin: 0 0 0 20px;
    }
    .event__title {
      font-weight: 700;
      color: $wcm-dark-orange;
      margin: 0 0 5px 0;
      @include breakpoint($sm) {
        margin: 0 0 5px 15px;
      }
      @include breakpoint($md) {
        font-size: 12px;
      }
      @include breakpoint($lg) {
        font-size: 14px;

      }
      a.external-link:after {
        display: none;
      }
    }
    .wcm-upcoming-event__date {
      span {
        display: block;
        text-align: center;
        line-height: 1.3;
        color: $wcm-med-gray;
      }
    }
    .wcm-upcoming-event__date___month {
      font-size: 20px;
    }
    .wcm-upcoming-event__date___day {
      font-size: 34px;
    }
    .wcm-upcoming-event__icon {
      display: none;
      @include breakpoint($sm) {
        position: relative;
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border-width: 0;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        left: -40px;
        margin-right: -20px;
        background: $wcm-white;
        &:hover {
          border-width: 0;
        }
        svg {
          position: absolute;
          width: 40px;
          height: 40px;
          fill: $wcm-bright-orange;
          color: $wcm-dark-orange;
          @include center();
        }
      }
    }
  }
}

@include breakpoint($sm) {
  .col-md-3 .wcm-upcoming-events,
  .col-md-2 .wcm-upcoming-events,
  .col-md-1 .wcm-upcoming-events {
    .wcm-upcoming-event {
      display: block;
      text-align: center;
      padding: 20px;
      .wcm-upcoming-event-icon-container {
        display: none;
      }
      .wcm-upcoming-event-info-container {
        margin: 0;
        .event__title {
          margin: 0;
        }
      }
    }
  }
}

/* =Upcoming Events List (Horizontal)
----------------------------------------------------------*/

.wcm-upcoming-events-section {
  margin: 20px 0 20px;
  @include clearfix();
}

.wcm-upcoming-events-inner-section {
  position: relative;
  @include breakpoint($sm) {
    padding: 30px 0 0;
  }
}

.wcm-upcoming-events__view-all {
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
  @include breakpoint($sm) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
  @include breakpoint($md) {
    font-size: 13px;
  }
}

.wcm-upcoming-event-horizontal {
  @include breakpoint($xs) {
    margin: 0 auto 20px;
    max-width: 300px;
  }
}

.wcm-upcoming-event-horizontal__image {
  position: relative;
  overflow: hidden;
  background-color: $wcm-black;
  a {
    display: block;
    border: none;
    &:hover {
      border: none;
    }
    &:focus {
      border: 5px solid $wcm-black;
    }
  }
  &:hover {
    .wcm-upcoming-event-horizontal__date {
      display: none;
    }
    a:before {
      opacity: 0;
    }
    img {
      transform: scale(1.1);
    }
  }
  img {
    width: 100%;
    transition: all 0.3s ease 0s;
  }
}

.wcm-upcoming-event-horizontal__date {
  color: $wcm-med-gray;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 1.3;
  font-family: $wcm-bold;
  letter-spacing: 2px;
  margin-top: 20px;
}

.wcm-upcoming-event-horizontal__time {
  font-size: 13px;
  margin: 10px 0 5px;
  @include breakpoint($md) {
    font-size: 15px;
    margin: 20px 0 10px;
  }
}

.wcm-upcoming-event-horizontal__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  @include breakpoint($md) {
    font-size: 16px;
  }
}

/* =Event Node
----------------------------------------------------------*/

.wcm-event {
  .field-event-link {
    // Prevent long links from overlapping sidebar
    a {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
